// Breakpoints
$large-width: 1140px;
$medium-width: 760px;
$small-width: 421px;

$section-max-width: 1100px;
$text-content-max-width: 850px;

$timeslot-bg: rgb(226, 243, 243);
$timeslot-bg-2: rgb(173, 205, 205);

$always-black: #000;

$light-bg: #fff;
$dark-text: #333;

$dark-bg: #3c3c3c;

$background-primary: $always-black;
$text-primary: #cdcdcd;
$border-radius: 4px;
$callout-border-radius: $border-radius;